import React, { useState } from "react";
import { SavedHub, HubData } from "../api/response-types";
import { FormInput } from "./form-input";
import { Modal } from "./modal";
import styles from "./hub-form.module.css";
import { useHistory } from "react-router-dom";

type HubFormProperties = {
  onFormSubmit: (formData: HubData, id?: number) => void;
  hubData?: SavedHub;
};

export const HubForm = ({ hubData, onFormSubmit }: HubFormProperties) => {
  const [state, setState] = useState({
    hubName: { value: `${hubData ? hubData.name : ""}`, error: "" },
    address: { value: `${hubData ? hubData.address : ""}`, error: "" },
    phone: { value: `${hubData ? hubData.phone : ""}`, error: "" },
    capacity: { value: `${hubData ? hubData.capacity : ""}`, error: "" },
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const history = useHistory();

  const onChangeState = (event: { target: { id: string; value: string } }) => {
    setState({
      ...state,
      [event.target.id]: { value: event.target.value, error: "" },
    });
  };

  const onSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (
      state.hubName.value &&
      state.address.value &&
      state.phone.value &&
      state.capacity.value
    ) {
      const data = {
        status: "ACTIVE",
        address: state.address.value,
        postcode: hubData ? hubData.postcode : "3000",
        country: hubData ? hubData.country : "Australia",
        capacity: Number.parseInt(state.capacity.value),
        name: state.hubName.value,
        phone: state.phone.value,
        latitude: hubData ? hubData.latitude : 1,
        longitude: hubData ? hubData.longitude : 2,
      };

      onFormSubmit(data, hubData && hubData.id);
    }
  };

  const onBlur = (event: { target: { id: string; value: string } }) => {
    if (!event.target.value) {
      setState({
        ...state,
        [event.target.id]: {
          value: event.target.value,
          error: "The field cannot be empty",
        },
      });
    }
  };

  return (
    <div className={styles.formContainer}>
      <form className={styles.editForm} onSubmit={onSubmit}>
        <div className={styles.narrowInput}>
          <FormInput
            label="Hub name"
            id="hubName"
            type="text"
            value={state.hubName.value}
            updateContent={onChangeState}
            onBlur={onBlur}
            errorMessage={state.hubName.error}
          />
        </div>
        <FormInput
          label="Address"
          id="address"
          type="text"
          value={state.address.value}
          updateContent={onChangeState}
          errorMessage={state.address.error}
          onBlur={onBlur}
        />
        <FormInput
          label="Phone number"
          id="phone"
          type="tel"
          value={state.phone.value}
          updateContent={onChangeState}
          errorMessage={state.phone.error}
          onBlur={onBlur}
        />
        {hubData && (
          <div className={styles.currentCapacity}>
            Current capacity: {hubData.capacity}
          </div>
        )}
        <div className={styles.narrowInput}>
          <FormInput
            label={hubData ? "Change capacity to:" : "Capacity"}
            id="capacity"
            type="number"
            value={state.capacity.value}
            updateContent={onChangeState}
            errorMessage={state.capacity.error}
            onBlur={onBlur}
          />
        </div>
        <div className={styles.formFooter}>
          <button type="submit" className="defaultBtn defaultBtn--filled">
            Save
          </button>
          <button
            className="defaultBtn defaultBtn--borderless"
            onClick={(event) => {
              event.preventDefault();
              setModalIsOpen(true);
            }}
          >
            Cancel
          </button>
          {modalIsOpen && (
            <Modal
              title="Are you sure you want to cancel?"
              message="You have unsaved changes that will be lost"
              onCancel={() => setModalIsOpen(false)}
              onOk={() => history.push("/admin/hubs")}
            />
          )}
        </div>
      </form>
    </div>
  );
};
