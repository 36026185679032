import React from "react";
import { useApiLogError } from "../context/api-error-context";
import { Cross } from "../assets/icons/cross";
import { Alert } from "../assets/icons/alert";
import styles from "./toast-notification.module.css";

type ToastNotificationProperties = {
  type: "ERROR" | "SUCCESS";
  text: string;
  id: number;
};

export function ToastNotification({
  // type,
  text,
  id,
}: ToastNotificationProperties) {
  const { dismissError } = useApiLogError();

  return (
    <div className={styles.toastContainer}>
      <Alert />

      {text}
      <button
        className={`closeBtn ${styles.dismissBtn}`}
        onClick={() => dismissError(id)}
      >
        <Cross />
      </button>
    </div>
  );
}
