import React from "react";
import { useOktaAuth } from "@okta/okta-react";

import styles from "./login.module.css";

export function Login() {
  const { oktaAuth } = useOktaAuth();

  const onLogin = () => {
    oktaAuth.signInWithRedirect();
  };

  return (
    <div className={styles.container}>
      <button className="defaultBtn defaultBtn--filled" onClick={onLogin}>
        Login
      </button>
    </div>
  );
}
