import { ApiResponseErrorItem } from "./response-types";

// https://javascript.info/custom-errors
export class ApiClientError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ApiClientError";
  }
}

export class ApiClientResponseError extends ApiClientError {
  apiErrors: ApiResponseErrorItem[];

  constructor(apiErrors: ApiResponseErrorItem[]) {
    // TODO: add details from apiErrors in message
    const message = "API Errors received";
    super(message);
    this.name = "ApiResponseError";
    this.apiErrors = apiErrors;
  }
}

export class ApiClientUnexpectedResponseError extends ApiClientError {
  constructor() {
    const message = "Unable to determine Errors from API response";
    super(message);
    this.name = "ApiResponseUnexpectedError";
  }
}
