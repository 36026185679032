import React, { createContext, useState, useContext } from "react";

import { ApiResponseErrorItem } from "../api/response-types";

type ApiError = ApiResponseErrorItem & { id: number; visible: boolean };

type ApiErrorLog = ApiError[];

type ApiErrorLogContextValues = {
  apiErrorLogState: ApiErrorLog;
  addError: (error: ApiResponseErrorItem) => void;
  deleteError: (id: number) => void;
  dismissError: (id: number) => void;
};

type ApiErrorProviderProperties = {
  children: React.ReactNode;
};

const ApiErrorLogContext = createContext<ApiErrorLogContextValues | null>(null);

export function useApiLogError() {
  const context = useContext(ApiErrorLogContext);
  if (!context) {
    throw new Error("useAppState must be used within an ApiErrorLogContext");
  }
  return context;
}

export function ApiErrorProvider({ children }: ApiErrorProviderProperties) {
  const [apiErrorLogState, setApiErrorLogState] = useState([] as ApiErrorLog);
  const addError = (error: ApiResponseErrorItem) =>
    setApiErrorLogState([
      { ...error, id: Date.now(), visible: true },
      ...apiErrorLogState,
    ]);

  const deleteError = (id: number) => {
    const newState = [...apiErrorLogState];
    newState.splice(
      apiErrorLogState.findIndex((error) => error.id === id),
      1
    );
    setApiErrorLogState(newState);
  };

  const dismissError = (id: number) => {
    const newState = [...apiErrorLogState];
    newState[apiErrorLogState.findIndex((error) => error.id === id)].visible =
      false;
    setApiErrorLogState(newState);
  };

  const contextValue = {
    apiErrorLogState,
    addError,
    deleteError,
    dismissError,
  };
  return (
    <ApiErrorLogContext.Provider value={contextValue}>
      {children}
    </ApiErrorLogContext.Provider>
  );
}
