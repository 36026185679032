import React from "react";
import { SavedHub } from "../api/response-types";
import styles from "./hubs-list.module.css";
import { StatusPill } from "./status-pill";
import { Link } from "react-router-dom";

type HubListProperties = {
  hubListData: SavedHub[];
};

export const HubsList = ({ hubListData }: HubListProperties) => {
  return (
    <div className={styles.hubList}>
      <table className={styles.hubTable}>
        <thead>
          <tr className={styles.hubTableHead}>
            <th>Location</th>
            <th>Status</th>
            <th>Address</th>
            <th>Contact</th>
            <th>Capacity</th>
          </tr>
        </thead>
        <tbody>
          {hubListData.map((hub) => (
            <tr className={styles.hubTableRow} key={hub.id}>
              <td className={styles.hubName}>
                <Link to={`/admin/hubs/${hub.id}/edit`}>{hub.name}</Link>
              </td>
              <td>
                <StatusPill isOpen={hub.capacity > 0} />
              </td>
              <td>
                {hub.address.split(",").map((chunk) => (
                  <React.Fragment key={chunk}>
                    {chunk} <br></br>
                  </React.Fragment>
                ))}
              </td>
              <td>{hub.phone}</td>
              <td>{hub.capacity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
