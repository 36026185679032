import React from "react";
import pageStyles from "../page.module.css";

import { Layout } from "../../components/layout";

export function Contacts() {
  return (
    <Layout>
      <div className={pageStyles.container}>
        <div className={pageStyles.contentHeader}>
          <h2 className={pageStyles.title}>Contacts</h2>
        </div>
        Coming soon
      </div>
    </Layout>
  );
}
