import React from "react";
import styles from "./form-input.module.css";

type InputProperties = {
  label: string;
  type: string;
  id: string;
  value: string;
  updateContent: (event: {
    target: {
      id: string;
      value: string;
    };
  }) => void;
  errorMessage: string;
  onBlur: (event: { target: { value: string; id: string } }) => void;
};

export const FormInput = ({
  label,
  type,
  id,
  value,
  updateContent,
  onBlur,
  errorMessage,
}: InputProperties) => {
  return (
    <div className={styles.inputContainer}>
      <label className={styles.inputLabel} htmlFor={id}>
        {label}
      </label>
      <input
        className={`${errorMessage ? styles["inputField--error"] : ""} ${
          styles.inputField
        }`}
        autoComplete="off"
        type={type}
        id={id}
        value={value}
        onChange={updateContent}
        onBlur={onBlur}
      />
      <div data-testid="error-container" className={styles.errorMessage}>
        {errorMessage}
      </div>
    </div>
  );
};
