import React from "react";
import styles from "./status-pill.module.css";

type StatusPillProperties = {
  isOpen: boolean;
};

export const StatusPill = ({ isOpen }: StatusPillProperties) => (
  <span
    className={`${styles.pill} ${isOpen ? styles.openPill : styles.closedPill}`}
  >
    {isOpen ? "Open" : "Closed"}
  </span>
);
