import React from "react";
import styles from "./layout.module.css";
import { NavigationBar } from "./nav-bar";
import logo from "../assets/mg_logo.svg";
import { useOktaAuth } from "@okta/okta-react";
import { useApiLogError } from "../context/api-error-context";
import { ToastNotification } from "./toast-notification";

type LayoutProperties = {
  children: React.ReactNode;
};

export function Layout({ children }: LayoutProperties) {
  const { oktaAuth } = useOktaAuth();
  const { apiErrorLogState } = useApiLogError();

  const visibleErrors = apiErrorLogState.filter((error) => error.visible);

  const logoutButtonClick = () => {
    oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/login`,
    });
  };

  return (
    <div>
      <div className={styles.header}>
        <img className={styles.logo} src={logo} alt="logo" />
        <h1 className={styles.appName}>Admin Connect</h1>
        <button className={styles.logoutButton} onClick={logoutButtonClick}>
          Logout
        </button>
      </div>
      <div className={styles.body}>
        <NavigationBar />
        <div className={styles.content}>
          {visibleErrors.map((error) => (
            <ToastNotification
              key={error.id}
              id={error.id}
              type="ERROR"
              text={error.detail}
            />
          ))}
          {children}
        </div>
      </div>
    </div>
  );
}
