import React, { MouseEventHandler, useEffect } from "react";
import { Cross } from "../assets/icons/cross";
import styles from "./modal.module.css";

type ModalProperties = {
  message: string;
  title: string;
  onOk: MouseEventHandler;
  onCancel: () => void;
};

export const Modal = ({ title, message, onOk, onCancel }: ModalProperties) => {
  useEffect(() => {
    const close = (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        onCancel();
      }
    };
    document.addEventListener("keydown", close);

    return () => document.removeEventListener("keydown", close);
  }, []);

  return (
    <div className={styles.modalBackdrop} onClick={onCancel}>
      <div
        className={styles.modalContent}
        onClick={(event) => event.stopPropagation()}
      >
        <div className={styles.modalCloseTitle}>
          <button className="closeBtn" onClick={onCancel}>
            <Cross />
          </button>
        </div>
        <h2 className={styles.modalHeader}>{title}</h2>
        <div className={styles.modalBody}>
          <p>{message}</p>
        </div>
        <div className={styles.modalFooter}>
          <button className="defaultBtn defaultBtn--filled" onClick={onOk}>
            Yes
          </button>
          <button className="defaultBtn" onClick={onCancel}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};
