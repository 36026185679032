/* eslint-disable unicorn/prefer-module */
import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { App } from "./app";

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.querySelector("#root")
);

if (module.hot) {
  module.hot.accept();
}
