import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import pageStyles from "../page.module.css";
import styles from "./edit-hub.module.css";
import { SavedHub, HubData } from "../../api/response-types";
import { useAppState } from "../../context/app-state-context";
import { useApiClient } from "../../context/api-context";
import { useApiLogError } from "../../context/api-error-context";
import { Layout } from "../../components/layout";
import { HubForm } from "../../components/hub-form";
import { StatusPill } from "../../components/status-pill";

export const EditHub = () => {
  const { id: hubId }: { id: string } = useParams();
  const { authState } = useOktaAuth();
  const apiClient = useApiClient();
  const { addError } = useApiLogError();
  const [hubLoading, setHubLoading] = useState(true);
  const [hubData, setHubData] = useState<SavedHub | null>(null);
  const history = useHistory();
  const {
    setAppState,
    appState: { hubListData },
  } = useAppState();

  useEffect(() => {
    const bearerToken = authState?.accessToken?.accessToken;
    // If we haven't got a list of hubs, we need to get them

    if (hubListData.length === 0 && bearerToken) {
      apiClient
        .searchHubs({ limit: 10, page: 1, bearerToken })
        .then(({ data }) => {
          setAppState({ hubListData: data });
          setHubLoading(false);
        });
    } else {
      setHubLoading(false);
    }
  }, []);

  useEffect(() => {
    const hub = hubListData.find((hub) => hub.id === Number.parseInt(hubId));
    setHubData(hub || null);
  }, [hubListData]);

  const onFormSubmit = (formData: HubData, id?: number) => {
    const bearerToken = authState?.accessToken?.accessToken;
    if (bearerToken && id) {
      apiClient
        .updateHub({ data: { hub: formData, id }, bearerToken })
        .then(() => {
          history.push("/admin/hubs");
        })
        .catch((error) => {
          addError(error.apiErrors[0]);
        });
    }
  };

  return (
    <Layout>
      <div className={pageStyles.container}>
        {/* Loading */}
        {hubLoading ? <div>Loading</div> : null}
        {/* No hub data */}
        {!hubLoading && !hubData ? (
          <div>Couldn&apos;t find a hub with an ID of: {hubId}</div>
        ) : null}
        {/* Render hub data */}
        {!hubLoading && hubData ? (
          <>
            <div className={styles.editHeader}>
              <h2>Edit {hubData.name} hub details</h2>
              <StatusPill isOpen={hubData.capacity > 0} />
            </div>
            <HubForm hubData={hubData} onFormSubmit={onFormSubmit} />
          </>
        ) : null}
      </div>
    </Layout>
  );
};
