import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import { useApiLogError } from "../../context/api-error-context";
import { HubData } from "../../api/response-types";
import { HubForm } from "../../components/hub-form";
import { Layout } from "../../components/layout";
import { useApiClient } from "../../context/api-context";
import pageStyles from "../page.module.css";

export const AddHub = () => {
  const apiClient = useApiClient();
  const { authState } = useOktaAuth();
  const history = useHistory();
  const { addError } = useApiLogError();

  const onFormSubmit = (formData: HubData) => {
    const bearerToken = authState?.accessToken?.accessToken;
    if (bearerToken) {
      apiClient
        .createHub({ data: { hub: formData }, bearerToken })
        .then(() => {
          history.push("/admin/hubs");
        })
        .catch((error) => {
          // Set error
          addError(error.apiErrors[0]);
        });
    }
  };
  return (
    <Layout>
      <div className={pageStyles.container}>
        <h2>Add hub</h2>
        <HubForm onFormSubmit={onFormSubmit} />
      </div>
    </Layout>
  );
};
