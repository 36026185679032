import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./nav-bar.module.css";

export const NavigationBar = () => {
  return (
    <div>
      <NavLink
        to="/admin/hubs"
        exact
        className={styles.navButton}
        activeClassName={styles.activeNavButton}
      >
        Hubs
      </NavLink>
      <NavLink
        to="/admin/contacts"
        className={styles.navButton}
        activeClassName={styles.activeNavButton}
      >
        Contacts
      </NavLink>
    </div>
  );
};
