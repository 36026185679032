import React from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Hubs } from "./pages/admin/hubs";
import { Login } from "./pages/login";
import { Contacts } from "./pages/admin/contacts";
import { ApiClientProvider } from "./context/api-context";
import { AppStateProvider } from "./context/app-state-context";
import { ApiErrorProvider } from "./context/api-error-context";
import { EditHub } from "./pages/admin/edit-hub";
import { AddHub } from "./pages/admin/add-hub";
import "./app.css";

const temporaryApiBaseUrl = process.env.RAZZLE_API_BASE_URL;

const oktaAuth = new OktaAuth({
  issuer: process.env.RAZZLE_AUTH_ISSUER,
  clientId: process.env.RAZZLE_AUTH_CLIENT_ID,
  redirectUri: window.location.origin + "/login/callback",
  pkce: true,
});

export function App() {
  const history = useHistory();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ApiClientProvider baseUrl={`${temporaryApiBaseUrl}`}>
        <ApiErrorProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/login/callback" component={LoginCallback} />
            <AppStateProvider>
              <SecureRoute exact path="/">
                {/* TODO: Dashboard will go here when developed */}
                <Redirect to="/admin/hubs" />
              </SecureRoute>
              <SecureRoute exact path="/admin/hubs" component={Hubs} />
              <SecureRoute exact path="/admin/hubs/add" component={AddHub} />
              <SecureRoute
                exact
                path="/admin/hubs/:id/edit"
                component={EditHub}
              />
              <SecureRoute exact path="/admin/contacts" component={Contacts} />
            </AppStateProvider>
          </Switch>
        </ApiErrorProvider>
      </ApiClientProvider>
    </Security>
  );
}
