import crossFetch from "cross-fetch";
import {
  ApiClientError,
  ApiClientResponseError,
  ApiClientUnexpectedResponseError,
} from "./errors";
import type {
  ApiResponse,
  CreateHubRequest,
  CreateHubResponse,
  SearchHubsRequest,
  SearchHubsResponse,
  UpdateHubRequest,
  UpdateHubResponse,
} from "./response-types";

type ApiClientOptions = {
  baseUrl: string;
  fetch?: typeof fetch;
};

export class ApiClient {
  baseUrl: string;
  _fetch: typeof fetch;

  constructor({ baseUrl, fetch = crossFetch }: ApiClientOptions) {
    this.baseUrl = baseUrl;
    this._fetch = fetch;
  }

  async _fetchAndHandleErrors<R = ApiResponse>(
    url: string,
    fetchOptions?: RequestInit
  ): Promise<R> {
    try {
      const response = await this._fetch(url, fetchOptions);
      const responseJson = await response.json();

      if (!response.ok) {
        // response.ok is only true when HTTP code is 200-299
        const responseErrors = responseJson.errors || [];

        // TODO: might be useful to give these errors more info about the the specific request/response
        if (responseErrors.length > 0) {
          throw new ApiClientResponseError(responseErrors);
        } else {
          throw new ApiClientUnexpectedResponseError();
        }
      }

      return responseJson as R;
    } catch (error) {
      // Network failure, or incomplete request
      if (error instanceof ApiClientError) {
        throw error;
      }
      throw new ApiClientError(error);
    }
  }

  async searchHubs(request: SearchHubsRequest): Promise<SearchHubsResponse> {
    const path = `/admin/hubs${
      request && `?page=${request.page}&limit=${request.limit}`
    }`;
    const url = this.baseUrl + path;

    return this._fetchAndHandleErrors<SearchHubsResponse>(url, {
      headers: {
        Authorization: `Bearer ${request.bearerToken}`,
      },
    });
  }

  async updateHub(request: UpdateHubRequest): Promise<UpdateHubResponse> {
    const path = `/admin/commands/updateHub`;
    const url = this.baseUrl + path;

    return this._fetchAndHandleErrors<UpdateHubResponse>(url, {
      body: JSON.stringify(request.data),
      method: "POST",
      headers: {
        Authorization: `Bearer ${request.bearerToken}`,
        "Content-Type": "application/json",
      },
    });
  }

  async createHub(request: CreateHubRequest): Promise<CreateHubResponse> {
    const path = `/admin/commands/createHub`;
    const url = this.baseUrl + path;

    return this._fetchAndHandleErrors<UpdateHubResponse>(url, {
      body: JSON.stringify(request.data),
      method: "POST",
      headers: {
        Authorization: `Bearer ${request.bearerToken}`,
        "Content-Type": "application/json",
      },
    });
  }
}
