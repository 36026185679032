import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { HubsList } from "../../components/hubs-list";
import { Layout } from "../../components/layout";
import { useApiClient } from "../../context/api-context";
import { useAppState } from "../../context/app-state-context";
import pageStyles from "../page.module.css";
import { Link } from "react-router-dom";

export function Hubs() {
  const apiClient = useApiClient();
  const { authState } = useOktaAuth();
  const { setAppState, appState } = useAppState();

  const [hubListLoading, setHubListLoading] = useState(true);

  useEffect(() => {
    const bearerToken = authState?.accessToken?.accessToken;
    if (bearerToken) {
      apiClient
        .searchHubs({ limit: 10, page: 1, bearerToken })
        .then(({ data }) => {
          setAppState({ hubListData: data });
          setHubListLoading(false);
        });
    }
  }, []);

  return (
    <Layout>
      <div className={pageStyles.container}>
        <div className={pageStyles.contentHeader}>
          <h2 className={pageStyles.title}>Hubs</h2>
          <Link to="/admin/hubs/add" className="defaultBtn defaultBtn--filled">
            Add a hub
          </Link>
        </div>
        {hubListLoading ? (
          <div>Loading</div>
        ) : (
          <HubsList hubListData={appState.hubListData} />
        )}
      </div>
    </Layout>
  );
}
