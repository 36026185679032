import React, { createContext, useContext } from "react";
import { ApiClient } from "../api/api-client";

type ApiClientProviderProperties = {
  children: React.ReactNode;
  baseUrl: string;
};

const ApiClientContext = createContext<ApiClient | null>(null);

export function useApiClient() {
  const context = useContext(ApiClientContext);
  if (!context) {
    throw new Error("useApiClient must be used within an ApiClientProvider");
  }
  return context;
}

export function ApiClientProvider({
  children,
  baseUrl,
}: ApiClientProviderProperties) {
  return (
    <ApiClientContext.Provider value={new ApiClient({ baseUrl })}>
      {children}
    </ApiClientContext.Provider>
  );
}
