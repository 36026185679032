import React from "react";

export const Cross = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M7.77319 7.00075L13.8396 0.934335C14.0532 0.720753 14.0532 0.374473 13.8396 0.160919C13.626 -0.0526356 13.2797 -0.052663 13.0662 0.160919L6.99975 6.22734L0.933359 0.160919C0.719777 -0.052663 0.373496 -0.052663 0.159942 0.160919C-0.0536122 0.3745 -0.0536395 0.720781 0.159942 0.934335L6.22633 7.00073L0.159942 13.0671C-0.0536395 13.2807 -0.0536395 13.627 0.159942 13.8406C0.266719 13.9473 0.406692 14.0007 0.546664 14.0007C0.686636 14.0007 0.826581 13.9473 0.933386 13.8406L6.99975 7.77417L13.0661 13.8406C13.1729 13.9473 13.3129 14.0007 13.4529 14.0007C13.5928 14.0007 13.7328 13.9473 13.8396 13.8406C14.0532 13.627 14.0532 13.2807 13.8396 13.0671L7.77319 7.00075Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
