import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
} from "react";
import { SavedHub } from "../api/response-types";

type AppState = {
  hubListData: SavedHub[];
};

type AppStateContextValues = {
  appState: AppState;
  setAppState: Dispatch<SetStateAction<AppState>>;
};

type AppStateProviderProperties = {
  children: React.ReactNode;
};

const AppStateContext = createContext<AppStateContextValues | null>(null);

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppStateContext");
  }
  return context;
}

export function AppStateProvider({ children }: AppStateProviderProperties) {
  const [appState, setAppState] = useState({ hubListData: [] } as AppState);
  return (
    <AppStateContext.Provider value={{ appState, setAppState }}>
      {children}
    </AppStateContext.Provider>
  );
}
